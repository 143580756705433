<template>
  <div>
    <NotificationFilter @apply="getAllNotificationsList" />
    <NotificationReminderContainer
      :update-pagination="updatePagination"
      :is-loading-component="isLoadingComponent"
      :notification-actions="notificationActions"
      @update="typeAction"
      @filterRecord="filterRecord"
    />
    <!-- SCHEDULED NOTIFICATION -->
    <ScheduledNotificationCreateUpdate
      v-if="scheduleModel"
      :show-model="scheduleModel"
      :label="currentActionObject.label"
      :options="currentActionObject.optionsList"
      :concerned="currentActionObject.concernedList"
      :feature-and-actions="currentActionObject"
      :edit="currentNotificationObject"
      @handleClick="toogleScheduleModel"
      @update="filterRecord"
    />
    <!-- UNSCHEDULED NOTIFICATION -->
    <UnScheduledNotificationCreateUpdate
      v-if="unScheduleModel"
      :show-model="unScheduleModel"
      :label="currentActionObject.label"
      :options="currentActionObject.optionsList"
      :feature-and-actions="currentActionObject"
      :edit="currentNotificationObject"
      module="syllabus_manager"
      @handleClick="toggleUnScheduleModel"
      @update="updateNotificationsList"
      @addNotificationAlert="addNotification"
    />
  </div>
</template>

<script>
import ScheduledNotificationCreateUpdate from '@/src/components/Settings/notifications/AddEditNotificationAlertTemplate1.vue'
import UnScheduledNotificationCreateUpdate from '@/src/components/Settings/notifications/CreateUnScheduleNotification.vue'
import NotificationReminderContainer from '@/src/components/Settings/notifications/NotificationReminderContainer.vue'
import { paginationRangeHandler } from '@components/BaseComponent/pagination.util.js'
import NotificationFilter from '@views/settings/notifications/attendance/campus/CampusAttendanceNotificationFilters.vue'
/* Utils or mixins */
import { mapActions } from 'vuex'
import notificationMixin from '@src/mixins/notification-mixin.js'
import NOTIFICATION_PATHS from '@src/constants/notification-constants.js'
import { getNotificationsOptionsList } from '@utils/notifications'
export default {
  components: {
    ScheduledNotificationCreateUpdate,
    UnScheduledNotificationCreateUpdate,
    NotificationReminderContainer,
    NotificationFilter,
  },
  mixins: [notificationMixin],
  data() {
    return {
      dataPayload: {
        payload: '',
        path: NOTIFICATION_PATHS.SYLLABUS_MANAGER,
      },
    }
  },

  methods: {
    setActionList() {
      this.notificationActions = {
        add_subject: {
          title: 'Reminder for Add Subject',
          label: 'Add Subject',
          feature: 'syllabus_manager',
          action: 'add_subject',
          loading: false,
          count: 0,
        },
        edit_subject: {
          title: 'Reminder for Edit Subject',
          label: 'Edit Subject',
          feature: 'syllabus_manager',
          action: 'edit_subject',
          loading: false,
          count: 0,
        },
        delete_subject: {
          title: 'Reminder for Delete Subject',
          label: 'Delete Subject',
          feature: 'syllabus_manager',
          action: 'delete_subject',
          loading: false,
          count: 0,
        },
        add_chapter: {
          title: 'Reminder for Add Chapter',
          label: 'Add Chapter',
          feature: 'syllabus_manager',
          action: 'add_chapter',
          loading: false,
          count: 0,
        },
        edit_chapter: {
          title: 'Reminder for Edit Chapter',
          label: 'Edit Chapter',
          feature: 'syllabus_manager',
          action: 'edit_chapter',
          loading: false,
          count: 0,
        },
        delete_chapter: {
          title: 'Reminder for Delete Chapter',
          label: 'Delete Chapter',
          feature: 'syllabus_manager',
          action: 'delete_chapter',
          loading: false,
          count: 0,
        },
        add_topic: {
          title: 'Reminder for Add Topic',
          label: 'Add Topic',
          feature: 'syllabus_manager',
          action: 'add_topic',
          loading: false,
          count: 0,
        },
        edit_topic: {
          title: 'Reminder for Edit Topic',
          label: 'Edit Topic',
          feature: 'syllabus_manager',
          action: 'edit_topic',
          loading: false,
          count: 0,
        },
        delete_topic: {
          title: 'Reminder for Delete Topic',
          label: 'Delete Topic',
          feature: 'syllabus_manager',
          action: 'delete_topic',
          loading: false,
          count: 0,
        },
        add_lesson: {
          title: 'Reminder for Add Lesson',
          label: 'Add Lesson',
          feature: 'syllabus_manager',
          action: 'add_lesson',
          loading: false,
          count: 0,
        },
        edit_lesson: {
          title: 'Reminder for Edit Lesson',
          label: 'Edit Lesson',
          feature: 'syllabus_manager',
          action: 'edit_lesson',
          loading: false,
          count: 0,
        },
        delete_lesson: {
          title: 'Reminder for Delete Lesson',
          label: 'Delete Lesson',
          feature: 'syllabus_manager',
          action: 'delete_lesson',
          loading: false,
          count: 0,
        },
      }
    },
    setConcernedAndOptionsList() {
      this.notificationActions.add_subject.optionsList = getNotificationsOptionsList([
        'all_teachers',
        'specific_staff',
        'concerned_teachers',
      ])
      this.notificationActions.edit_subject.optionsList = getNotificationsOptionsList([
        'all_teachers',
        'specific_staff',
        'concerned_teachers',
      ])
      this.notificationActions.delete_subject.optionsList = getNotificationsOptionsList([
        'all_teachers',
        'specific_staff',
        'concerned_teachers',
      ])
      this.notificationActions.add_chapter.optionsList = getNotificationsOptionsList([
        'all_teachers',
        'specific_staff',
        'concerned_teachers',
      ])
      this.notificationActions.edit_chapter.optionsList = getNotificationsOptionsList([
        'all_teachers',
        'specific_staff',
        'concerned_teachers',
      ])
      this.notificationActions.delete_chapter.optionsList = getNotificationsOptionsList([
        'all_teachers',
        'specific_staff',
        'concerned_teachers',
      ])
      this.notificationActions.add_topic.optionsList = getNotificationsOptionsList([
        'all_teachers',
        'specific_staff',
        'concerned_teachers',
      ])
      this.notificationActions.edit_topic.optionsList = getNotificationsOptionsList([
        'all_teachers',
        'specific_staff',
        'concerned_teachers',
      ])
      this.notificationActions.delete_topic.optionsList = getNotificationsOptionsList([
        'all_teachers',
        'specific_staff',
        'concerned_teachers',
      ])
      this.notificationActions.add_lesson.optionsList = getNotificationsOptionsList([
        'all_teachers',
        'specific_staff',
        'concerned_students',
        'concerned_teachers',
      ])
      this.notificationActions.edit_lesson.optionsList = getNotificationsOptionsList([
        'all_teachers',
        'specific_staff',
        'concerned_students',
        'concerned_teachers',
      ])
      this.notificationActions.delete_lesson.optionsList = getNotificationsOptionsList([
        'all_teachers',
        'specific_staff',
        'concerned_students',
        'concerned_teachers',
      ])
    },

    setRightBar() {
      const rightBarContent = {
        header: {
          title: 'NOTIFICATION_REMINDER',
          buttons: [
            {
              title: 'SUBJECT_NOTIFICATIONS',
              classes: ['primary-button-right'],
              options: [
                {
                  title: 'ADD_SUBJECT',
                  action: {
                    func: () => {
                      this.currentActionObject = this.notificationActions.add_subject
                      this.toggleUnScheduleModel()
                    },
                  },
                  payload: {},
                },

                {
                  title: 'EDIT_SUBJECT',
                  action: {
                    func: () => {
                      this.currentActionObject = this.notificationActions.edit_subject
                      this.toggleUnScheduleModel()
                    },
                  },
                  payload: {},
                },
                {
                  title: 'DELETE_SUBJECT',
                  action: {
                    func: () => {
                      this.currentActionObject = this.notificationActions.delete_subject
                      this.toggleUnScheduleModel()
                    },
                  },
                  payload: {},
                },
              ],
            },
            {
              title: 'CHAPTER_NOTIFICATIONS',
              classes: ['primary-button-right'],
              options: [
                {
                  title: 'ADD_CHAPTER',
                  action: {
                    func: () => {
                      this.currentActionObject = this.notificationActions.add_chapter
                      this.toggleUnScheduleModel()
                    },
                  },
                  payload: {},
                },

                {
                  title: 'EDIT_CHAPTER',
                  action: {
                    func: () => {
                      this.currentActionObject = this.notificationActions.edit_chapter
                      this.toggleUnScheduleModel()
                    },
                  },
                  payload: {},
                },
                {
                  title: 'DELETE_CHAPTER',
                  action: {
                    func: () => {
                      this.currentActionObject = this.notificationActions.delete_chapter
                      this.toggleUnScheduleModel()
                    },
                  },
                  payload: {},
                },
              ],
            },
            {
              title: 'TOPIC_NOTIFICATIONS',
              classes: ['primary-button-right'],
              options: [
                {
                  title: 'ADD_TOPIC',
                  action: {
                    func: () => {
                      this.currentActionObject = this.notificationActions.add_topic
                      this.toggleUnScheduleModel()
                    },
                  },
                  payload: {},
                },

                {
                  title: 'EDIT_TOPIC',
                  action: {
                    func: () => {
                      this.currentActionObject = this.notificationActions.edit_topic
                      this.toggleUnScheduleModel()
                    },
                  },
                  payload: {},
                },
                {
                  title: 'DELETE_TOPIC',
                  action: {
                    func: () => {
                      this.currentActionObject = this.notificationActions.delete_topic
                      this.toggleUnScheduleModel()
                    },
                  },
                  payload: {},
                },
              ],
            },
            {
              title: 'LESSON_NOTIFICATIONS',
              classes: ['primary-button-right'],
              options: [
                {
                  title: 'ADD_LESSON',
                  action: {
                    func: () => {
                      this.currentActionObject = this.notificationActions.add_lesson
                      this.toggleUnScheduleModel()
                    },
                  },
                  payload: {},
                },

                {
                  title: 'EDIT_LESSON',
                  action: {
                    func: () => {
                      this.currentActionObject = this.notificationActions.edit_lesson
                      this.toggleUnScheduleModel()
                    },
                  },
                  payload: {},
                },
                {
                  title: 'DELETE_LESSON',
                  action: {
                    func: () => {
                      this.currentActionObject = this.notificationActions.delete_lesson
                      this.toggleUnScheduleModel()
                    },
                  },
                  payload: {},
                },
              ],
            },
          ],
        },
      }
      this.setRightbarContent(rightBarContent)
    },
    ...mapActions('layout', ['setRightbarContent']),
  },
}
</script>

<style lang="scss" module>
.minHieght80 {
  min-height: 80vh;
}
</style>
